import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

export default function Home() {
  const categories = useStaticQuery<GatsbyTypes.CategoryIndexQuery>(
    graphql`
      query CategoryIndex {
        allCategoryJson {
          nodes {
            id
            code
            name
            icon
            count
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    `,
  );

  const theme = useTheme();
  const MotionBox = motion(Box);
  return (
    <Flex
      width="100%"
      height={['auto', `calc(100vh - ${theme.sizes['14']})`]}
      direction={['column', 'row']}
      wrap={['nowrap', 'wrap']}
      overflow="auto"
    >
      {categories.allCategoryJson.nodes.map(category => (
        <Box
          key={category.id}
          sx={{
            h: '50%',
            flex: '0 0 50%',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <MotionBox
            h="100%"
            whileHover={{ scale: 1.07 }}
            transition={{ duration: 0.5 }}
          >
            <Image
              alt="category logo"
              as={GatsbyImage}
              h="100%"
              image={category?.image?.childImageSharp?.gatsbyImageData!}
            />
          </MotionBox>
          <Box
            sx={{
              position: 'absolute',
              left: '40px',
              bottom: '30px',
            }}
          >
            <Heading my={4} size="lg">
              <Link
                as={GatsbyLink}
                color="whiteAlpha.800"
                variant="now"
                to={`/${category.code}`}
              >
                <Text
                  as="span"
                  fontSize="2xl"
                  mr={4}
                  className={`iconfont ${category.icon}`}
                />
                {category.name}
              </Link>
            </Heading>
            <Text color="whiteAlpha.800">{category.count}篇文章</Text>
          </Box>
        </Box>
      ))}
    </Flex>
  );
}
